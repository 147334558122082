.modal-dialog{
    max-width: 600px!important;
    text-align: center;
    color: #000;
}
#modal_start_campaign .modal-header{
    background-color: #EBAAE7;
    padding: 15px 20px;
}
.modal-logo{
    height: 28px;
    width: auto;
}
.modal-body__title{
    color: #000;
    font-weight:800;
    font-size: 43px;
    font-family: "Kanit";
    margin-top: 10px;
}
.modal-body__note{
    font-family:"TRIAL Rooftop";
    font-weight: 300;
    font-size: 16px;
    padding: 0 24px;
}
.modal-body__second-note{
    font-family: "TRIAL Rooftop";
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 38px;
    padding: 0 60px;
    margin-top: 28px;
}
.modal-body__buttons{
    display: flex;
    justify-content: center;
    margin-top: 55px;
}
.modal-body__buttons > button{
    padding: 9px 20px;
    border: 1px solid #000;
    font-family: "TRIAL Rooftop";
    font-weight: 400;
    font-size: 16px;
    background-color: #fff;
}
.modal-body__buttons > button:nth-of-type(1){
    background-color: #A5B4E3;
    margin-right: 12px;
}

.modal__payment-header{
    font-size: 20px;
    font-weight: 400;
    font-family: "Kanit";
    color: #A9A6A6;
    margin-top: 74px;
}
.payment-package__header{
    font-size: 30px;
    font-family: "Kanit";
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 69px;
}
.payment__startup-package{
    background-color: #EBABE3;
    padding: 0 28px;
}
.payment__premium-package{
    background-color: #E45909;
    padding: 0 28px;
}
.payment-package__price{
    font-size: 50px;
    font-family: "Kanit";
    font-weight: 700;
    margin-bottom: 0;
    line-height: 49px;
}
.payment-package__period{
    font-size: 16px;
    font-family: "Kanit";
    font-weight: 400;
    margin-bottom: 40px;
}
.payment-package__button{
    width: 100%;
    background-color: #fff;
    font-size: 20px;
    font-family: "Kanit";
    font-weight: 400;
    color: #000;
    border-radius: 0;
    margin: 0 17px 69px 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment-package__button>img{
    margin-left: 5px;
}
.payment-package__button:hover{
    background-color: #fff;
}

.payment__premium-package > .btn,
.payment__startup-package > .btn{
    background-color: #fff;
}