@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Extralight.otf");
  font-weight: 200;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Bold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "TRIAL Rooftop";
  src: url("./assets/fonts/TRIAL_Rooftop-Extrabold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit-ExtraBold.ttf");
  font-weight: 800;
}
